<template>
  <div>
    <!-- Title -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.title') }}</label>
      <div class="col-md-7">
        <input v-model="form.title" :class="{ 'is-invalid': form.errors.has('title') }" class="form-control" type="text" name="title">
        <has-error :form="form" field="title" />
      </div>
    </div>

    <!-- Date -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.date') }}</label>
      <div class="col-md-7">
        <input v-model="form.date" :class="{ 'is-invalid': form.errors.has('date') }" class="form-control" type="date" name="date">
        <has-error :form="form" field="date" />
      </div>
    </div>
    
    <!-- Video -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.videoUrl') }}</label>
      <div class="col-md-7">
        <input v-model="form.videoUrl" :class="{ 'is-invalid': form.errors.has('videoUrl') }" class="form-control" type="text" name="videoUrl">
        <has-error :form="form" field="videoUrl" />
      </div>
    </div>

    <!-- Thumbnail -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.thumbnail') }}</label>
      <div class="col-md-7">
        <img v-bind:src="form.thumbnailUrl" v-if="form.thumbnailUrl" class="mg-b-15"/>
        <div class="custom-file">
          <input v-on:change="changeThumbnail" :class="{ 'is-invalid': form.errors.has('thumbnail') }" class="custom-file-input" type="file" name="thumbnail" id="thumbnail">
          <has-error :form="form" field="thumbnail" />
          <label class="custom-file-label" for="thumbnail">{{ thumbnailLabel }}</label>
        </div>
      </div>
    </div>

    <!-- Description -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.description') }}</label>
      <div class="col-md-7">
        <textarea v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }" class="form-control" rows="5" name="description"></textarea>
        <has-error :form="form" field="description" />
      </div>
    </div>

    <!-- Topics -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.topics') }}</label>
      <div class="col-md-7">
        <v-select 
          multiple
          taggable
          v-model="form.topics"
          :options="topicOptions"
          :get-option-key="getTopicKey"
          :create-option="option => ({ text: option, topic: option.toLowerCase().split(' ').join('_') })"
          label="text" />
      </div>
    </div>

    <!-- Verses -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.verses') }}</label>
      <div class="col-md-7">
        <input v-model="form.verses" :class="{ 'is-invalid': form.errors.has('verses') }" class="form-control" type="text" name="verses">
        <has-error :form="form" field="verses" />
      </div>
    </div>

    <!-- Speaker -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('sermons.speaker') }}</label>
      <div class="col-md-7">
        <v-select 
          v-model="form.speaker_id" 
          :options="speakerOptions" 
          :reduce="speaker => speaker.id" 
          label="name" />
      </div>
    </div>

    <!-- Series -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('series.default') }}</label>
      <div class="col-md-7">
        <v-select 
          v-model="form.series_id" 
          :options="seriesOptions" 
          :reduce="series => series.id" 
          label="title" />
      </div>
    </div>

  </div>
</template>

<script>
  import _ from 'lodash'
  import vSelect from 'vue-select'
  import { mapGetters } from "vuex"

  export default {
    props: {
      form: { 
        type: Object,
        required: true
      },
    },
    components: {
      "v-select": vSelect
    },
    
    async created () {
      this.$store.dispatch('public/fetchTopics');      
      this.$store.dispatch('account/fetchSpeakers');
      this.$store.dispatch('account/fetchSeries');
    },

    computed: {
      ...mapGetters({
        topicOptions: "public/topicsArray",
        speakerOptions: 'account/speakersArray',
        seriesOptions: 'account/seriesArray',
      }),
      thumbnailLabel: function() {
        return this.form.thumbnail ? this.form.thumbnail.name : 'Choose file';
      },
    },
    methods: {
      changeThumbnail(e) {
        const file = e.target.files[0];
        this.form.thumbnail = file;
      },

      // Search functions for speakers
      onSpeakerSearch(search, loading) {
        if(search.length) {
          loading(true);
          this.speakerSearch(loading, search, this);
        }
      },
      speakerSearch: _.debounce((loading, search, vm) => {
        vm.$store.dispatch('account/fetchSpeakers', {q: escape(search)})
          .then((data) => { 
            vm.speakerOptions = data.speakers;
            loading(false);
          });
      }, 350),

      // Search function for series
      onSearch(search, loading) {
        if(search.length) {
          loading(true);
          this.search(loading, search, this);
        }
      },
      search: _.debounce((loading, search, vm) => {
        vm.$store.dispatch('account/fetchSeries', {q: escape(search)})
          .then((data) => { 
            vm.seriesOptions = data.series;
            loading(false);
          });
      }, 350),

      // Topics
      getTopicKey(option) {
        if (typeof option !== 'object') {
          return option;
        }
        return option.topic;
      }
    }
  }
</script>

<style scoped>
@import '~vue-select/dist/vue-select.css';
</style>

<style scope="scss">
img {
  max-width: 100%;
  max-height: 15rem;
}
.input-group-prepend .input-group-text svg {
  width: 1rem !important;
  height: 1rem !important;
}
</style>